import React, { useEffect, useState } from 'react';
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Menu  from './Menu';

const HeaderTab = {
  HOME: 0,
  ABOUT: 1,
  CONTACT: 2,
  FILMS: 3,
  WORK: 4
}

const Header = React.memo(() => {
    const [isOpen, setOpen] = useState(false);

    const links = (
      <>
        <Link to="/" name={HeaderTab.HOME} onClick={() => setOpen(false)} activeClassName="  border-gray-500" className={`block  lg:inline-block lg:mt-0  px-4`}>
          Hjem
        </Link>
        <Link to="/films" name={HeaderTab.FILMS} onClick={() => setOpen(false)}  activeClassName="border-gray-500" className={`block lg:inline-block lg:mt-0  px-4`}>
          Filmer
        </Link>
        <Link to="/work" name={HeaderTab.WORK} onClick={() => setOpen(false)}  activeClassName="border-gray-500" className={`block lg:inline-block lg:mt-0  px-4`}>
          Oppdrag
        </Link>
        <Link to="/about" name={HeaderTab.ABOUT} onClick={() => setOpen(false)}  activeClassName=" border-gray-500" className={`block  lg:inline-block lg:mt-0 px-4`}>
          Om oss
        </Link>
        <Link to="/contact" name={HeaderTab.CONTACT} onClick={() => setOpen(false)} activeClassName=" border-gray-500" className={`block  lg:inline-block lg:mt-0  px-4`}>
          <div className="hidden md:flex  rounded-sm  text-white items-center text-sm justify-center bg-tb-dark cursor-pointer" style={{height: "40px", width: "130px"}}>Kontakt oss</div>
        </Link>
      </>
    )

    return <div className="relative sticky top-0 z-10 "><nav id="header" class="bg-tb-yellow flex items-center justify-between flex-wrap bg-teal py-3.5  mx-auto px-4">
    <Menu isOpen={isOpen} setOpen={setOpen}/>
    <Link to="/" className="md:px-8 lg:px-16 flex items-center flex-no-shrink text-tb-dark  md:mr-6">
      <StaticImage src="../images/logo.jpg" alt="The branch logo" className="h-12 w-12 mr-2"  />
      <span class="font-semibold text-lg tracking-tight">The Branch</span>
    </Link>
    <Link to="/contact" name={HeaderTab.CONTACT} onClick={() => setOpen(false)} activeClassName="text-blue-800  border-gray-500" className={`block lg:inline-block lg:mt-0 text-tb-dark`}>
          <div className="md:hidden flex  rounded-lg  text-white items-center justify-center bg-tb-dark cursor-pointer" style={{height: "40px", width: "85px"}}>Kontakt</div>
     </Link>
     <div class="hidden text-base md:flex items-center text-tb-dark">
        {links}
      </div>
  </nav>
  {isOpen && <div class={`mobile-menu filter drop-shadow pl-8 absolute bg-tb-dark z-10 w-full ${isOpen ? 'open' : ''}`}> 
        <ul className="text-white">
         {links}
        </ul>
      </div>}
  </div>
});

export default Header;